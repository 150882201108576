// Color
// @color-bright-1: #FFFFFF;

// @color-blue-1: #F9FAFF;
// @color-blue-2: #E9ECFB;
// @color-blue-3: #D7DEFE;
// @color-blue-4: #BDC7FC;
// @color-blue-5: #2E3AEB;


// @color-dark-0: #D6D6D6;
// @color-dark-1: #7D7D7D;
// @color-dark-2: #6B6B6B;
// @color-dark-3: #555555;
// @color-dark-4: #434343;
// @color-dark-5: #29292c;
// @color-dark-6: #010002;

// @color-red: #FF0B00;
// @color-green: #028E19;

// Background
@background-color-primary: @color-blue-2;
@background-color-secondary: @color-blue-3;
@background-color-tertiary: @color-blue-5;
@background-body: @color-blue-1;

body {
  background-color: @background-body;
}

// Font Color
@font-color-default: @color-dark-1;
@font-color-primary: @color-dark-4;
@font-color-secondary: @color-dark-3;
@font-color-active: #fff;

@font-family: 'MicrosoftYaHeiLight', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

@text-color: @font-color-default;

// Base Scaffolding Variables
// ---
// @font-size-base: 16px;
// @font-size-lg: 18px;
// @font-size-sm: 14px;

// Border
@border-radius-base: 5px;
@border-color-base: @color-blue-3; // base border outline a component


// Layout
@layout-body-background: @background-body;
@layout-header-background: @color-bright-1;
@layout-header-height: 64px;
@layout-header-padding: 15px 10px;

.ant-layout {

  &-header {
    box-sizing: border-box;
  }

  &-sider {
    padding-right: 1px;
  }

  &-content {
    height: calc(100vh - 80px);
    overflow: hidden;
    overflow-y: auto;
  }
}

// Menu
// ---
@menu-inline-submenu-bg: #fff;
@menu-selected-bg: @color-blue-3;
@menu-item-active-bg: @color-blue-3;
@menu-highlight-color: #fff;
@menu-item-font-size: @font-size-sm;
@menu-collapsed-width: 80px;
@menu-inline-toplevel-item-height: 45px;

// 默认菜单样式
.ant-menu {
  font-weight: 500;
  border-right: none;
  font-size: @font-size-base;

  &-item-active {
    background-color: @menu-item-active-bg;
  }

  .anticon {
    font-size: @font-size-base;
  }

  &-submenu &-item {
    font-size: @font-size-sm;
  }

  // 菜单栏选择下样式
  &-submenu-selected {
    .ant-menu-submenu-title {
      background: @menu-selected-bg;
    }

    .ant-menu-submenu-arrow {
      &::after {
        background-image: none !important;
        background-color: @menu-highlight-color  !important;
      }

      &::before {
        background-image: none !important;
        background-color: @menu-highlight-color  !important;
      }
    }
  }

  // 菜单鼠标移入样式
  &-submenu-active {
    .ant-menu-submenu-title {
      background: @menu-selected-bg;
    }
  }

  // 菜单栏标题右侧图标
  &-submenu-arrow {
    transform: none !important;

    &::before {
      width: 4px !important;
      height: 1px !important;
      transform: none !important;
    }

    &::after {
      width: 4px !important;
      height: 1px !important;
      transform: rotate(90deg) translateX(0px) !important;
    }
  }

  // 菜单栏打开下样式
  &-submenu-open {
    .ant-menu-submenu-arrow {
      ::before {
        background-image: none !important;
        background-color: @menu-highlight-color  !important;
      }

      &::after {
        background: none !important;
      }
    }
  }
}


// Buttons
@btn-font-size: @font-size-base;
@btn-font-weight: 500;
@btn-border-radius: 16rpx;

@btn-default-color: @color-dark-2;
@btn-default-border: @color-blue-3;

@btn-primary-color: @color-dark-6;
@btn-primary-bg: #6C72F3;

@btn-link-bg: @background-color-primary;
@btn-link-hover-bg: @background-color-tertiary;
@btn-link-color: @color-bright-1;
@btn-link-hover-color: @color-bright-1;

.ant-btn {
  font-size: @btn-font-size;
  font-weight: 500;
  line-height: @btn-font-size;
  padding: 6px 26px;
  min-width: 88px;

  &[disabled],
  &[disabled]:hover {
    background: @color-disabled;
  }

  &.is-active,
  &:hover,
  &:active,
  &:focus {
    opacity: 0.8 !important;
    color: @btn-default-color;
    border-color: @btn-default-border;
  }

  &-primary {
    border: none;

    &.is-active,
    &:hover,
    &:active,
    &:focus {
      color: @btn-primary-color;
      background-color: @btn-primary-bg;
    }
  }

  &-link {
    background-color: @btn-link-bg;
    font-size: @font-size-sm;
    color: @btn-link-color;
    border: none;
    padding: 6px 16px;

    &.is-active,
    &:hover,
    &:active,
    &:focus {
      background-color: @btn-link-hover-bg;
      color: @btn-link-hover-color;
    }
  }

  &-round {
    // height: 32px;
  }

  &+& {
    margin-left: 14px;
  }

  &-sm {
    min-width: unset;
  }

  &-text:hover,
  &-text:active,
  &-text:focus {
    border-color: transparent;
  }
}

// Switch
@switch-color: @color-blue-5;

// Tag
@tag-default-bg: @color-bright-1;
@tag-default-color: @color-blue-5;
@tag-font-size: @font-size-sm;
@tag-line-height: 1.5;
@tag-border-radius: 10px;
@tag-min-width: 90px;
@tag-text-align: center;
@tag-font-weight: 500;

@tag-close-icon-width: 16px;
@tag-close-icon-height: 16px;
@tag-close-icon-top: -6px;
@tag-close-icon-right: -6px;
@tag-close-icon-bg: red;
@tag-close-icon-color: @color-bright-1;
@tag-close-icon-size: 12px;
@tag-border-color: @color-blue-4;

.ant-tag {
  border-radius: @tag-border-radius  !important;
  text-align: @tag-text-align;
  font-weight: @tag-font-weight;
  border-color: @tag-border-color;
  position: relative;
  margin: 0 5px;
  padding: 6px 16px;
  cursor: pointer;
  min-width: 90px;
  box-shadow: 0px 3px 3px 0px rgba(33, 34, 34, 0.12);

  &-close-icon {
    position: absolute;
    width: @tag-close-icon-width;
    height: @tag-close-icon-height;
    top: @tag-close-icon-top;
    right: @tag-close-icon-right;
    background-color: @tag-close-icon-bg;
    color: @tag-close-icon-color;
    border-radius: 50%;
    font-size: @tag-close-icon-size;
    padding: 2px;

    &:hover {
      color: @color-bright-1;
    }
  }

  &.tag-checked,
  &:hover,
  &:focus,
  &:active {
    background-color: @background-color-tertiary;
    color: @color-bright-1;
  }
}

// Table
@table-header-bg: @color-blue-3;
@table-header-color: @color-bright-1;
@table-header-weight: 500;
@table-body-weight: 500;
@table-font-size: 14px;
@table-selected-row-color: @font-color-active;
@table-selected-row-bg: @background-color-secondary;
@table-selected-row-hover-bg: @background-color-secondary;
@table-row-hover-bg: @background-color-secondary;
@table-row-hover-color: @font-color-active;
@table-padding-vertical: 15px;
@table-padding-horizontal: 16px;
@table-border-radius-base: 0px;

.ant-table {
  font-size: @font-size-sm;
  font-weight: @table-body-weight;
  line-height: @font-size-sm;

  &-thead {
    >tr {
      >th {
        font-weight: @table-header-weight;
        padding: 12px;
      }
    }
  }

  &-tbody {
    >tr {
      >td {
        padding: 12px;
      }
    }
  }

  &-row-selected &-cell {
    color: @font-color-active;
  }

  &-row:hover {
    color: @table-row-hover-color;
  }

  &-selection {
    justify-content: center;
  }
}


// Pagination

@pagination-item-min-width: 61px;
@pagination-item-mini-min-width: 40px;
@pagination-item-mini-margin-right: 6px;
@pagination-item-radius: 16px;

@pagination-item-size: 24px;

.ant-pagination {

  font-size: @font-size-sm;

  &-item,
  &.mini &-item {
    border-radius: 16px;

    &:hover,
    &:active,
    &:focus {
      border: none;
      background-color: @background-color-tertiary;

      a {
        color: @color-bright-1;
      }
    }
  }

  &-item {
    min-width: @pagination-item-min-width;
  }

  &.mini &-item {
    min-width: @pagination-item-mini-min-width;
    margin-right: @pagination-item-mini-margin-right;
  }

  &-item-active {
    border: none;
    background-color: @background-color-tertiary;

    &:hover a,
    &:focus a,
    &:active a,
    a {
      color: @color-bright-1;
    }
  }


  &-item-link {
    min-width: @pagination-item-min-width;
    border-radius: 16px !important;

    .anticon svg {
      display: none;
    }
  }

  &-next &-item-link,
  &-prev &-item-link,
  &.mini &-next &-item-link,
  &.mini &-prev &-item-link {
    position: relative;
    background-color: @background-color-primary;
    color: @color-bright-1;
    border: none;

    &::after {
      position: absolute;
      content: "上一页";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: @background-color-tertiary;
      color: @color-bright-1;
    }
  }

  &.mini &-next,
  &.mini &-prev {
    min-width: @pagination-item-mini-min-width;
    margin-right: @pagination-item-mini-margin-right;
  }

  &-next &-item-link::after,
  &.mini &-next &-item-link::after {
    content: "下一页";
  }

}

// Input
@input-hover-border-color: @color-blue-2;
@outline-color: @color-blue-2;
@placeholder-color: @color-blue-4;
@input-affix-margin: 6px;
@input-height-base: 32px;

.ant-input {
  border-color: @input-hover-border-color;
  font-size: @font-size-sm;
  font-weight: 500;
  border-radius: 5px;
  padding: 6px;
  line-height: 22px;

  &-round&-affix-wrapper {
    border-radius: 16px;
    line-height: 18px;
    padding: 4px 11px;
  }

  &-affix-wrapper {
    border-color: @input-hover-border-color;
  }

  &-affix-wrapper>& {
    padding: 1px;
  }

  &::placeholder {
    color: @placeholder-color;
  }

}

// Select
@select-item-selected-bg: @background-color-secondary;
@select-item-active-bg: @background-color-secondary;
@select-item-selected-color: @font-color-active;
@select-placeholder-color: @color-blue-4;
@select-dropdown-font-size: @font-size-sm;
@select-border-color: @color-blue-2;

.ant-select {
  font-size: @font-size-sm;
  font-weight: 500;
  border-radius: 5px;
  width: 100%;
  border-color: @select-border-color;

  &-round&:not(&-customize-input) &-selector {
    border-radius: 16px;
  }

  &-round &::placeholder {
    color: @placeholder-color;
  }


  &-selection-placeholder {
    color: @select-placeholder-color;
  }

}

// Picker
.ant-picker {
  width: 100%;
  font-size: @font-size-sm;
  padding: 4px 11px;

  &-round {
    border-radius: 16px;
  }

  &-input>input {
    font-size: @font-size-sm;

    &::placeholder {
      color: @placeholder-color;
    }
  }

  &-dropdown {
    font-size: @font-size-sm;
  }
}

// Checkbox
.ant-checkbox {
  &-inner {
    border-radius: 2px;
  }

}

// Drawer
@drawer-mask-bg: transparent;

.ant-drawer {
  &-mask {
    background: @drawer-mask-bg;
  }

  &-header {
    padding: 14px 17px;
  }

  &-close {
    padding: 17px;
  }

  &-title {
    text-align: left;
  }

  &-body {
    padding: 14px 17px;
  }

  &-content-wrapper {
    padding-left: 30px;
    background: rgba(0, 0, 0, 0.3);
  }
}

// Base

.money {
  font-size: @font-size-base;

  &-red {
    color: @color-red;
  }

  &-green {
    color: @color-green;
  }

  &-red::before,
  &-green::before {
    content: '￥';
  }
}

.statu {
  font-size: @font-size-base;

  &-red {
    color: @color-red;
  }

  &-green {
    color: @color-green;
  }
}