.list-container {
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 5px 12px 3px rgba(33, 34, 34, 0.1);
  overflow: hidden;
  padding: 15px 0;


  &.inline-block {
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

.list-header {
  padding: 0 27px 0 15px;
  white-space: nowrap;
  color: @color-dark-4;

  &-title {
    margin-bottom: 20px;
    color: @color-dark-4;
    font-weight: bold;
  }

  &-content {

    margin-bottom: 20px;

    &-item {
      font-size: 14px;
      margin-left: 60px;
      font-weight: bold;
    }

    &-statu-item {
      font-size: 14px;
      margin-right: 50px;
      font-weight: bold;
    }

    &-money {
      font-size: @font-size-lg;
    }

    &-statu {
      font-size: @font-size-lg;
    }
  }


}

.list-buttons {
  padding: 0 15px 20px;
}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #90A4FE;@color-blue-3: #6C72F3;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-dark-0: #D6D6D6;@color-dark-1: #323232;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-disabled: #F5F5F5;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;