.tree-container {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;

  .tree-list-box {
    width: 500px;
    height: 100%;
    min-height: 500px;
    position: relative;
    border: 2px solid #EBEBEC;
    border-radius: 8px;
    margin-right: 1%;
    vertical-align: top;
    overflow-y: auto;
    padding: 18px 10px 18px 40px;
    font-size: 20px;
  }

  .tree-form-box {
    flex: 1;
    height: 100%;
    min-height: 500px;
    position: relative;
    overflow-y: auto;
    background: #fff;
    border: 2px solid #EBEBEC;
    border-radius: 8px;
    vertical-align: top;
    flex-direction: column;

    .tree-list-header {
      padding: 11px 20px 10px 20px;
    }

    .tree-form-content {
      width: 700px;
      padding: 10px;
    }

    .tree-init-box {
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
  }

}