.toolbar {
  padding: 0px 0px 10px 15px;
  display: flex;
  flex-flow: row nowrap;

  .modules-name {
    display: inline-block;
    font-weight: 500;
    color: #7d7d7d;
    min-width: 120px;
    margin-right: 15px;
    margin-top: 4px;
    vertical-align: top;
  }

  /*订单搜索*/
  .pub-search-box {
    display: inline-block;
    flex: 1;
    vertical-align: top;
  }

  .pub-search-right {
    display: inline-block;
    margin-left: 14px;

  }

}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #90A4FE;@color-blue-3: #6C72F3;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-dark-0: #D6D6D6;@color-dark-1: #323232;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-disabled: #F5F5F5;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;