.drawer {
  position: fixed;
  right: 0;
  top: 65px;
  height: calc(100vh - 65px);
  z-index: 1000;

  /* 遮罩 */
  .drawer-mask-warp {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  .drawer-mask-show {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: opacity 0.5s;
  }

  .drawer-mask-hide {
    opacity: 0;
    transition: opacity 0.5s;
  }

  /* 滑块 */
  .drawer-main {
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    height: 100%;
    background: #fff;
    border-radius: 4px 0px 0px 4px;
    transition: all 0.5s;

    [data-visible='true']&::before {
      content: " ";
      position: absolute;
      top: 0;
      left: -42px;
      width: 42px;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }

    /* 其他样式 */
    .drawer-head {
      display: flex;
      justify-content: space-between;
      height: 48px;
      line-height: 48px;
      padding: 0 22px;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid #E0E0E0;

      .close-btn {
        display: inline-block;
        cursor: pointer;
        height: 100%;
        padding-left: 20px;
        // margin-top: -8px;
      }
    }

    .drawer-body {
      font-size: 14px;
      padding: 15px;
      max-height: calc(100% - 180px);
      min-height: 300px;
      overflow-y: auto;
    }

    .drawer-footer {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 34px 20px 0;
      border-top: 1px solid #E0E0E0;

      span {
        display: block;
        width: 118px;
        min-width: 60px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        cursor: pointer;
        background: #aaa;
        color: @color-bright-1;
        margin-bottom: 10px;

        &:last-child {
          background: #D6D6D6;
          margin-right: 0;
          color: @color-dark-6;
        }

        &:hover {
          -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
          -webkit-transform: translate3d(0, -2px, 0);
          transform: translate3d(0, -2px, 0);
        }
      }

      .submit-button {
        background: #BDC7FC;
        color: #fff;
      }
    }
  }

  .drawer-main-show {
    opacity: 1;
  }

  .drawer-main-hide {
    opacity: 0;
  }
}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #90A4FE;@color-blue-3: #6C72F3;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-dark-0: #D6D6D6;@color-dark-1: #323232;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-disabled: #F5F5F5;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;